
import * as unsplashRuntime$liWKZiqKxC from '/Users/cdm/code/turbo/super-devise/node_modules/@nuxt/image/dist/runtime/providers/unsplash'
import * as pexelsRuntime$jzyecPBIyZ from '/Users/cdm/code/turbo/super-devise/node_modules/@nuxt/image/dist/runtime/providers/imgix'
import * as mediavaletRuntime$jzyecPBIyZ from '/Users/cdm/code/turbo/super-devise/node_modules/@nuxt/image/dist/runtime/providers/imgix'
import * as localRuntime$jzyecPBIyZ from '/Users/cdm/code/turbo/super-devise/node_modules/@nuxt/image/dist/runtime/providers/imgix'
import * as ipxRuntime$2VKrws2ptL from '/Users/cdm/code/turbo/super-devise/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['unsplash']: { provider: unsplashRuntime$liWKZiqKxC, defaults: undefined },
  ['pexels']: { provider: pexelsRuntime$jzyecPBIyZ, defaults: undefined },
  ['mediavalet']: { provider: mediavaletRuntime$jzyecPBIyZ, defaults: {"baseURL":"https://lbmx.imgix.net"} },
  ['local']: { provider: localRuntime$jzyecPBIyZ, defaults: {"baseURL":"https://lbmx.imgix.net"} },
  ['ipx']: { provider: ipxRuntime$2VKrws2ptL, defaults: {} }
}
        